@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@400;500;600&display=swap");
:root {
  --beige: #feefc4;
  --brown: #c75227;
}
body {
  background: var(--beige);
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--beige);
  position: relative;
}

.flex div {
  min-width: 550px;
}

footer {
  font-family: "Fredoka", "Sans-Serif";
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 4.75rem;
  margin-bottom: 1em;
}

.loadMore {
  cursor: pointer;
  font-family: "Fredoka", "Sans-Serif";
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  width: fit-content;
  color: var(--beige);
  background-color: var(--brown);
  border-radius: var(--width-400);
  font-size: 1.375rem;
  margin-inline: 0.25rem;
  padding: 0.5rem 0.5rem;
  white-space: nowrap;
  margin-top: 2rem;
  border-radius: 12px;
}

.loading .twitter-tweet {
  margin: 0 !important;
}

.loaded .twitter-tweet {
  margin: auto;
}

.flex.loading {
  min-height: 80vh;
}
@media screen and (max-width: 768px) {
  .flex div {
    min-width: 90%;
  }
  .footer {
    margin-top: 1.313rem;
  }

  .flex.loading {
    min-height: 70vh;
  }
}
